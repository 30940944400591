export function ButtonGroupCustomStyles() {
  return (
    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
      <div className="row flex">
        <button
          className="rounded-md rounded-r-none bg-slate-800 py-2 px-4 border-2 border-slate-500 text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
        >
          One
        </button>
        <button
          className="rounded-none bg-slate-800 py-2 px-4 border-2 border-l-0 border-r-0 border-slate-500 text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
        >
          Two
        </button>
        <button
          className="rounded-md rounded-l-none bg-slate-800 py-2 px-4 border-2 border-slate-500 text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
        >
          Three
        </button>
      </div>
    </div>
  );
}
